
import { isEmpty, isNull } from "lodash";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
export default {
	name: "CardTextField",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {},
		rules: {
			type: Array,
			default: function () {
				return [];
			},
		},
		hint: {
			type: String,
			default: "",
		},
		reference: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: "",
		},
		customClass: {
			type: String,
			default: "label-fixed static",
		},
		counter: {
			type: [Number, Boolean],
			default: 255,
		},
		showLabel: {
			type: Boolean,
			default: true,
		},
		is_upload: {
			type: Boolean,
			default: false,
		},
		persistent_hint: {
			type: Boolean,
			default: false,
		},
		icon_add: {
			type: Boolean,
			default: false,
		},
		prepend_inner_icon: {
			type: String,
			default: "",
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		error: {
			type: Boolean,
			default: false,
		},
		messages: {
			type: String,
			default: "",
		},
		required: {
			type: Boolean,
			default: false,
		},
		withMessageRequire: {
			type: Boolean,
			default: false,
		},
		messageRequire: {
			type: String,
			default: "",
		},
		type: {
			type: String,
			default: "text",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		valueNumber: {
			type: Number,
			default: undefined,
		},
		valueText: {
			type: String,
			default: undefined,
		},
		prefix: {
			type: String,
			default: undefined,
		},
		suffix: {
			type: String,
			default: undefined,
		},
		error_messages: {
			type: [Array, String],
			default: function () {
				return [];
			},
		},
		showCounter: {
			type: Boolean,
			default: true,
		},
		hide_details: {
			type: [Boolean, String],
			default: false,
		},
		persistent_placeholder: {
			type: Boolean,
			default: true,
		},
		cardStyles: {
			type: String,
			default: "px-2 mb-2 static",
		},
		append_outer_data: {
			type: Object,
			default: function () {
				return {};
			},
		},
		single_line: {
			type: Boolean,
			default: false,
		},
		min: {
			type: [Number, undefined],
			default: undefined,
		},
		max: {
			type: [Number, undefined],
			default: undefined,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		dataCy: {
			type: String,
			default: ""
		},
	},
	data: function () {
		return {};
	},
	components: { IconBase },
	mounted() {},
	computed: {
		isLoading() {
			return this.$store.state.proccess.loading_field;
		},
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},
		getCustomClass() {
			if (this.disabled) {
				return this.customClass + " disabled";
			} else {
				return this.customClass;
			}
		},
		getValue() {
			if (this.valueNumber) return this.valueNumber;
			if (this.valueText) return this.valueText;
			return undefined;
		},
		hasAppendOuterData() {
			return (
				!isNull(this.append_outer_data) &&
				Object.entries(this.append_outer_data).length > 0
			);
		},
	},
	methods: {
		isEmpty(data: any) {
			return isEmpty(data);
		},
		clickAppendOuter(params: any) {
			this.$emit("click-append-outer", params);
		},
		clickAppend() {
			this.$emit("click-append", this.modelData);
		},
		changeEvent(e: any) {
			this.$emit("change", e);
		},
		blurEvent(e: any) {
			this.$emit("blur", this.modelData);
		},
		handleFocus(e: any) {
			this.$emit("focus", e);
		},
		enterHandler(e: any) {
			this.$emit("enter", e);
		},
	},
	watch: {},
};

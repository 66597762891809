import Vue from "vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
// @ts-ignore
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
// @ts-ignore
import CardTextarea from "@/components/Content/CardTextarea.vue";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
import {
	isRequired,
    isMaxLength,
	isAfterCompare,
	isSameOrAfterToday,
	isAfterToday,
	isAfterCompareAndAfterToday,
    isMax,
    isMin
	// @ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import { initRequestCampaign } from "@/utils/initData";
// @ts-ignore
import { isUndefined, isEmpty, isNull } from "lodash";
// @ts-ignore
import { getError } from "@/utils/resolveObjectArray";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated } from "@/interfaces/paginated";
// @ts-ignore
import ParamService from "@/services/params-service";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import DialogNotes from "../Dependencies/Dialog/DialogNotes.vue";
// @ts-ignore
import { mapActions } from "vuex"; 

// Configs to Date
const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const MIN_KPI_OBJETIVE = 20000;

//STATUS
const STATUS_PENDING = "ext_status_pending";
const STATUS_APPROVED = "ext_status_approved";
const STATUS_INREVIEW = "ext_status_inreview";
const STATUS_STARTED = "ext_status_started";
const STATUS_COMPLETED = "ext_status_completed";
const STATUS_CANCELLED = "ext_status_cancelled";

//Buy Model
const BUY_MODEL_CPP = "ext_buy_model_cpp";
const BUY_MODEL_CPI = "ext_buy_model_cpi";

// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

const matchedSelectedTypeLink = {
	"User Acquisition Media": "https://adsmovil.com/help_center/docs/ADQUISICION%20DE%20USUARIOS_NI_2022.pptx"
}

export default Vue.extend({
    name: "LogicOverviewRequestCampaign",
    props:{
        idRequestCampaign: {
            type: Number,
            default: undefined,
        },

        edit: {
            type: Boolean,
            default: false,
        },

        account: {
			type: Object,
			default: function () {
				return {};
			},
		},
    },
    components:{ 
        CardTextField,
        CardAutocomplete,
        DatePicker,
        DataPickerStarTime,
        Separator,
        CardActions,
        CardTextarea,
        Dialog,
        DialogNotes
    },
    data: () => ({
        valid: true,
        fieldEdit: false,
        viewModal: false,
        loadingNotes: false,
        lastStatus: undefined,
        requestCampaign: initRequestCampaign(),
        resources: {
            campaignType: [],
            providers: [],
            buyModel: [],
            campaignMMP: [],
            campaignOwner: [],
            statusCampaign: [],
        },
        notesResources: {
            note: "",
            paginated: { page: 1, limit: 25 } as Paginated,
            filters: {},
            options: getDefaultSortingOption("date_note"),
            note_history: []
        },
        rules: {
            typeCampaignRule: [],
            ownerRule: [],
            nameRule: [],
            brandRule: [],
            startDateRule: [],
            endDateRule: [],
            buyModelRule: [],
            budgetRule: [],
            kpiRule: [],
            mmpRule: [],
            trackingLinkRule: [],
            urlGoogleRule: [],
            statusRule: []
        }

    }),
    created(){
        this.clearErrors();
    },
    mounted(){
        this.$nextTick(async () => {
            await this.load();
        });
    },
    computed:{

        isEdit(){
            return this.edit
        },

        getIdRequestCampaign(): Number{
            if(!isUndefined(this.requestCampaign?.id)){
                return this.requestCampaign.id;
            }else{
                return this.idRequestCampaign;
            }
        },

        getCampaignRequest(){
            return this.requestCampaign;
        },

        getRules() {
			return {
				isRequired,
				isMaxLength,
                isAfterCompare,
				isSameOrAfterToday,
				isAfterToday,
				isAfterCompareAndAfterToday,
                isMax,
                isMin,
			};
		},

        getCampaignType(){
            return this.resources.campaignType;
        },

        getCampaignOwner(){
            return this.resources.campaignOwner;
        },

        getCampaignMMP(){
            return this.resources.campaignMMP;
        },

        getBuyModel(){
            return this.resources.buyModel;
        },

        getProvider(){
            return this.resources.providers;
        },

        getStatus(){
            if(!isEmpty(this.resources.statusCampaign)){
                if(!this.requestCampaign.status_id){
                    return this.getStatusByID(this.lastStatus);
                }else{
                    this.lastStatus = this.requestCampaign.status_id;
                    return this.getStatusByID(this.requestCampaign.status_id);
                }   
            }
        },

        getHistoryNotes(){
            return this.notesResources.note_history;
        },

        getMinDate() {
			return this.moment().format(DEFAULT_DATE_TIME_FORMAT);
		},

		getMinEndDate() {
			return this.moment(this.requestCampaign.start_date).format(
				DEFAULT_DATE_TIME_FORMAT
			);
		},

        getCurrency() {
			return !isUndefined(this.account.currency) &&
                !isEmpty(this.account.currency)
                ? `${this.account.currency.key} (${this.account.currency.glyph})`
                : "N/A";
		},

        getErrors() {
			return this.$store.state.proccess.errors;
		},

        getKpiObjectiveLabel() {
			let label = this.$t('requestCampaign.label.objetive');
			this.rules.kpiRule = [];
            if(!isEmpty(this.requestCampaign.kpi)){
                this.rules.kpiRule = [
                    this.getRules.isRequired,
                    this.getRules.isMin(
                        this.requestCampaign.kpi,
                        MIN_KPI_OBJETIVE
                    ),
                ];
            }
			return label;
		},

        isBuyModelCPI(){
            if(isUndefined(this.getBuyModelByID(this.requestCampaign.buy_model_id))){
                return true;
            }else{
                return this.getBuyModelByID(this.requestCampaign.buy_model_id).extra == BUY_MODEL_CPI;
            }
        },

		getSelectedTypeLink() {
			return matchedSelectedTypeLink[this.requestCampaign?.type?.value];
		}

    },
    methods:{
		...mapActions("loading", ["setLoadingData"]),

        ...mapActions("request_campaign", [
            "create",
            "update",
            "show",
            "getListData",
            "createNote",
            "paginatedNotes"
        ]),

        ...mapActions("proccess", [
			"setLoadingField",
            "setErrors"
        ]),

        clearErrors(){
            this.setErrors({});
        },

        clearRules() {
			this.rules.typeCampaignRule = [];
            this.rules.ownerRule = [];
            this.rules.nameRule = [];
            this.rules.brandRule = [];
            this.rules.startDateRule = [];
            this.rules.endDateRule = [];
            this.rules.buyModelRule = [];
            this.rules.budgetRule = [];
            this.rules.kpiRule = [];
            this.rules.mmpRule = [];
            this.rules.trackingLinkRule = [];
            this.rules.urlGoogleRule = [];
            this.rules.statusRule = [];
		},
        
        handleCancel() {
			this.$router.push({ name: "RequestCampaignIndex" });
		},

        removeDataResources(key: string){
            this.resources[key] = [];
        },

        isValidDates() {
			const startDate = this.moment(this.requestCampaign.start_date);
			const endDate = this.moment(this.requestCampaign.end_date);
			return startDate.isValid() && endDate.isValid();
		},

		calculateDuration(start: any, end: any) {
			if (isUndefined(start) || isUndefined(end)) return;
			if (!(start.isValid() && end.isValid())) return -1;

			const duration = this.moment.duration(end.diff(start));
			return Math.ceil(duration.asDays());
		},

        getError(index: any) {
			return getError(this.getErrors, index);
		},

        getStatusByID(idStatus: number){
            if(!idStatus) return;
            let filtro = new Array();
            let status = this.getStatusByExtra(this.resources.statusCampaign.find(s => s.id == idStatus).extra);
            switch(status.extra){
                case STATUS_PENDING: //(Estado pendiente)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_PENDING)); //(Pendiente)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_APPROVED)); //(Aprobada)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_INREVIEW)); //(En revision)
                    break;
                case STATUS_APPROVED: //(Estado aprobada)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_APPROVED)); //(Aprobada)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_INREVIEW)); //(En revision)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_STARTED)); //(Iniciada)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_CANCELLED)); //(Cancelada)
                    break;
                case STATUS_INREVIEW: //(Estado en revision)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_INREVIEW)); //(En revision)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_APPROVED)); //(Aprobada)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_CANCELLED)); //(Cancelada)
                    break;
                case STATUS_STARTED: //(Estado iniciada)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_INREVIEW)); //(En revision)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_STARTED)); //(Iniciada)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_COMPLETED)); //(Finalizada)
                    filtro.push(this.resources.statusCampaign.find(s => s.extra == STATUS_CANCELLED)); //(Cancelada)
                    break;
                default:
                    filtro = this.resources.statusCampaign;
            }
            return filtro;
        },
        
        getStatusByExtra(status: string = STATUS_PENDING){
            return this.resources.statusCampaign.find(s => s.extra == status);
        },

        getFirtOrDefaultProvidersID(){
            return this.getProvider[0].id;
        },

        getCalculateDuration() {
			if (!this.isValidDates()) return;

			const startDate = this.moment(this.requestCampaign.start_date);
			const endDate = this.moment(this.requestCampaign.end_date);

			let days = this.calculateDuration(startDate, endDate);

			if (days < 0) {
				this.campaign.end_date = "";
				this.requestCampaign.duration = undefined;
				return;
			}

			this.requestCampaign.duration = days;
		},

        getBuyModelByID(idModel: number){
            if(isEmpty(this.resources.buyModel)) return undefined;
            return this.resources.buyModel.find((b: { id: number; }) => b.id == idModel);
        },

        setFieldEditable(){
            if(isUndefined(this.requestCampaign.status_id))return;
            if(this.isEdit){
                if(!this.requestCampaign.status_id){
                    this.fieldEdit = true;
                }else{
                    let status = this.getStatusByExtra(this.resources.statusCampaign.find(s => s.id == this.requestCampaign.status_id).extra);
                    this.fieldEdit = !(status.extra != STATUS_STARTED && status.extra != STATUS_COMPLETED);
                }
            }else{
                this.fieldEdit = false;
            }
        },

        async updatePagePaginatedNotes(){
            this.notesResources.paginated.page = this.notesResources.paginated.page + 1;
        },

        async showModal(params: any){
            this.viewModal = params.modal;
            if(params.fetch){
                await this.fetchNotes();
            }
            if(!params.modal){
                this.notesResources.note_history = [];
                this.notesResources.paginated.page = 1;
            }
        },

        async setNoteHistory(values: any){
            if(!isEmpty(values)){
                values.forEach(n => {
                    this.notesResources.note_history.push(n);
                });
            }
        },

        async load(){
            if(this.isEdit){
                await this.setLoadingField(true);
                await this.loadResources()
                .then(() => {
                    this.loadEditResources();
                });
                await this.setLoadingField(false);
            }else{
                await this.setLoadingField(true);
                await this.fetchResources("providers", "description", false, false);
                await this.fetchResources("statusCampaign", "description", true, false);
                await this.setLoadingField(false);
            }
        },

        async loadResources(){
            await this.fetchResources("providers", "description", false, false);
            await this.fetchResources("statusCampaign", "description", true, false);
            await this.fetchResources("campaignType", "description", false, false);
            await this.fetchResources("buyModel", "description", true, false);
            await this.fetchResources("campaignOwner", "name", false, false);
            await this.fetchResources("campaignMMP", "description", false, false);
        },

        async loadEditResources(){
            await this.show(this.getIdRequestCampaign)
            .then((response) => {
                this.parseEntity(response);
                this.$emit("set-entity", response);
            });
        },

        async addRules() {
			this.rules.nameRule = [
				this.getRules.isRequired,
				this.getRules.isMaxLength
			];
            this.rules.typeCampaignRule = [this.getRules.isRequired];
            this.rules.ownerRule = [this.getRules.isRequired];
            this.rules.brandRule = [this.getRules.isRequired];
            this.rules.startDateRule = [this.getRules.isRequired];
            this.rules.buyModelRule = [this.getRules.isRequired];
            this.rules.budgetRule = [this.getRules.isRequired];
            this.rules.kpiRule = [this.getRules.isRequired, this.getRules.isMin(this.requestCampaign.kpi, MIN_KPI_OBJETIVE)];
            this.rules.mmpRule = [this.getRules.isRequired];
            this.rules.trackingLinkRule = [this.getRules.isRequired];
            this.rules.urlGoogleRule = [this.getRules.isRequired];
            this.rules.statusRule = [this.getRules.isRequired];
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

        async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
					await this.handleSubmit({ 
                        handle: (this.isEdit || this.requestCampaign?.id) ? 1 : 0,
                        msg: (this.isEdit || this.requestCampaign?.id)
                            ? TypeLoading.update
                            : TypeLoading.create,
                        redirect: true,
                        continue: false
                    });
					break;

				case "save-continue":
					await this.handleSubmit({ 
                        handle: (this.isEdit || this.requestCampaign?.id) ? 1 : 0,
                        msg: (this.isEdit || this.requestCampaign?.id)
                        ? TypeLoading.update
                        : TypeLoading.create,
                        redirect: false,
                        continue: true
                    });
					break;

				case "cancel":
					this.handleCancel();
					break;
			}
		},

        async handleSubmit(params: any){
            try {
				await this.addRules();

				if (!(await this.validate())) return;

                if(!this.isEdit && isUndefined(this.requestCampaign.id)){
                    this.requestCampaign.provider_id = this.getFirtOrDefaultProvidersID();
                    this.requestCampaign.status_id = this.getStatusByExtra().id;
                }

                switch(params.handle){
                    case 0:
                        let paramsCreate = {
                            data: this.requestCampaign,
                            id: (this.isEdit || this.requestCampaign?.id) ? this.getIdRequestCampaign : undefined,
                            redirect: params.continue ? undefined : "RequestCampaignIndex"
                        };
                        await this.handleSubmitCreate(paramsCreate, params.msg);
                        break;
                    case 1:
                        let paramsUpdate = {
                            data: this.requestCampaign,
                            id: (this.isEdit || this.requestCampaign?.id) ? this.getIdRequestCampaign : undefined,
                            redirect: params.redirect ? "RequestCampaignIndex" : undefined
                        };
                        await this.handleSubmitUpdate(paramsUpdate, params.msg);
                        break;
                }
				
				this.clearRules();

                if(params.continue){
                    this.$emit("update-tab", 1);
                }
			} catch (error) {
				this.clearRules();
			}
        },

        async handleSubmitCreate(params: any, msg: any){
            try{
				await this.setLoadingData(msg);
                const response = await this.create(params);
                this.requestCampaign = response;
                this.$emit("set-entity", response);
                await this.setLoadingData();
            }catch (error) {
                await this.setLoadingData();
			}
            
        },

        async handleSubmitUpdate(params: any, msg: any){
            try{
                await this.setLoadingData(msg);
                const response = await this.update(params);
                this.requestCampaign = response;
                await this.setLoadingData();
            }catch (error) {
                await this.setLoadingData();
			}
        },

        async handleNotes(){
            if(!isEmpty(this.notesResources.note)){
                await this.setLoadingData(TypeLoading.loading);
                let params = {
                    note: this.notesResources.note,
                    date_note: new Date(),
                    ext_camp_id: this.getIdRequestCampaign
                };
                await this.createNote(params);
                this.notesResources.note = "";
                await this.setLoadingData();
            }
        },

        async fetchResources(keyField: string, attrField: string = "name", addExtra: boolean = false, loading: boolean = true){
            let params = {
                key: keyField,
                keyAttr: "id",
                attr: attrField,
                extra: addExtra
            }
            if(loading) await this.setLoadingField(true);
            this.removeDataResources(keyField);
            this.resources[keyField] = await this.getListData(params);
            if(loading) await this.setLoadingField(false);
        },

        async fetchNotes(){
            this.notesResources.filters = { ext_camp_id: String(this.getIdRequestCampaign) }
            this.loadingNotes = true;
            const response = await this.paginatedNotes(await ParamService.getParams(
                this.notesResources.paginated,
                this.notesResources.filters,
                this.notesResources.options
            ));
            this.loadingNotes = false;
            await this.setNoteHistory(response.data);
        },

        async parseEntity(entity: any){
			const type = { id: entity.type.id, value: entity.type.description };
            this.requestCampaign.id = entity.id,
            this.requestCampaign.type = type,
            this.requestCampaign.type_id = entity.type_id,
            this.requestCampaign.owner_id = entity.owner_id,
            this.requestCampaign.name = entity.name,
            this.requestCampaign.brand = entity.brand,
            this.requestCampaign.start_date = entity.start_date,
            this.requestCampaign.end_date = entity.end_date,
            this.requestCampaign.buy_model_id = entity.buy_model_id,
            this.requestCampaign.budget = entity.budget,
            this.requestCampaign.kpi = entity.kpi,
            this.requestCampaign.mmp_id = entity.mmp_id,
            this.requestCampaign.mmp_traking_link = entity.mmp_traking_link,
            this.requestCampaign.url_google_play = entity.url_google_play,
            this.requestCampaign.provider_id = entity.provider_id,
            this.requestCampaign.status_id = entity.status_id,
            this.requestCampaign.currency = this.account.currency.key;
        }

    },
    watch: {
        "requestCampaign.start_date"() {
			this.getCalculateDuration();
		},

		"requestCampaign.end_date"() {
			this.getCalculateDuration();
		},

        "requestCampaign.status_id"(){
            this.setFieldEditable();
        },
		
		"requestCampaign.type": {
			handler(val) {
				this.requestCampaign.type_id = val?.id || null;
			},
		}
    }
});
import Vue from "vue";
// @ts-ignore
import FormCreative from "@/views/Admin/RequestCampaign/Dependencies/Creatives/FormCreative.vue";
// @ts-ignore
import TableCreative from "@/views/Admin/RequestCampaign/Dependencies/Creatives/TableCreative.vue";

export default Vue.extend({
    name: "LogicAssociatedCreative",
    props:{
        campaign:{
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    components:{
        FormCreative,
        TableCreative
    },
    data: () => ({
        creative: {},
        editCreative: false,
        items: [
            {
                key: 0,
                visible: true
            },
            {
                key: 1,
                visible: false
            }
        ]
    }),
    created(){},
    mounted(){},
    computed:{
        isFormCreativeSelected(){
            return this.items.find(i => i.key == 1).visible;
        },  

        isTableCreativeSelected(){
            return this.items.find(i => i.key == 0).visible;
        },
        
        getCampaignRequest(){
            return this.campaign;
        },

        getCreative(){
            return this.creative;
        },

        getEditCreative(){
            return this.editCreative;
        }
    },
    methods:{

        setFormCreative(){
            this.items.find(i => i.key == 0).visible = false;
            this.items.find(i => i.key == 1).visible = true;
        },

        setTableCreative(){
            this.items.find(i => i.key == 0).visible = true;
            this.items.find(i => i.key == 1).visible = false;
            this.editCreative = false;
            this.creative = {};
        },

        setEditCreative(entity: any){
            this.creative = entity;
            this.editCreative = true;
            this.setFormCreative();
        }

    },
    watch:{}
});